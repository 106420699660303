import { push } from 'react-router-redux';
import { saveState } from '../LocalStorage';

const axios = require('axios');
const SERVER = process.env.REACT_APP_SERVER;

export const Types = {
    LOGIN_STARTED: 'LOGIN STARTED',
    ERROR_OCCURED: 'ERROR OCCURED',
    LOGGED_IN: 'LOGGED IN',
    SIGN_IN_STARTED: 'SIGN IN STARTED',
    USER_INFO: 'USER INFO',
    LOGOUT: 'LOGOUT',
    SEND_EMAIL_STARTED: 'SEND_EMAIL_STARTED',
    SEND_EMAIL_COMPLETED: 'SEND_EMAIL_COMPLETED',
}

export const login = (email, password, storeCredentials) => {
    return function(dispatch, getState) {
        dispatch({type: Types.LOGIN_STARTED});

        let url = SERVER + '/users/login';
        axios.post(url, { "user": { "email": email, "password": password }})
            .then(response => {
                dispatch({type: Types.LOGGED_IN, token: response.data.user.token});

                if (storeCredentials) {
                    saveState({userState: getState().userState});
                }
                
                dispatch(push('./feed'));
            })
            .catch(err => dispatch({type: Types.ERROR_OCCURED, error: err}));
    }
}

export const signIn = (email, password) => {
    return function(dispatch) {
        dispatch({type: Types.SIGN_IN_STARTED});

        let url = SERVER + '/users';
        axios.post(url, { "user": { "email": email, "password": password }})
            .then(response => dispatch({type: Types.LOGGED_IN, token: response.data.user.token}))
            .catch(err => dispatch({type: Types.ERROR_OCCURED, error: err}));
    }
}

export const getUserInfo = () => {
    return function(dispatch, getState) {
        let url = SERVER + '/user';
        axios.get(url, { headers: { Authorization: 'Bearer ' + getState().userState.token }})
            .then(response => dispatch({type: Types.USER_INFO, userInfo: response.data.user}))
            .catch(err => dispatch({type: Types.ERROR_OCCURED, error: err}));
    }
}

export const logout = () => {
    return function(dispatch, getState) {
        dispatch({type: Types.LOGOUT});
        saveState({userState: getState().userState});
        dispatch(push('./'));
    }
}

export const contactByEmail = (email, subject, text) => {
    return function(dispatch, getState) {
        dispatch({type: Types.SEND_EMAIL_STARTED});

        let url = SERVER + '/contact';
        let payload = {
            data: { 'email': email, 'subject': subject, 'text': text },
        };

        axios.post(url, payload)
            .then(response => {
                dispatch({type: Types.SEND_EMAIL_COMPLETED, status: response.status, info: response.info});
            })
            .catch(err => dispatch({type: Types.ERROR_OCCURED, error: err}));
    }
}

