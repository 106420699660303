import { initialState as userState } from './users/reducers';
import { initialState as statisticsState } from './statistics/reducers';
import { initialState as eventsState } from './events/reducers';
import { initialState as notificationsState } from './notifications/reducers';

export const rootState = {
    userState: userState,
    statisticsState: statisticsState,
    eventsState : eventsState,
    notificationsState: notificationsState,
};