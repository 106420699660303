import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import memoize from "memoize-one";
import * as StatisticsActions  from '../../store/statistics/actions';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import i18next from 'i18next';

import './QuerySelection.css'

class QuerySelection extends React.Component {
    static defaultProps = {
        limit: 10,
        title: "",
        selector: "",
        data: [],
        selectedItems: []
    }

    state = {query: "", selectedItems: this.props.selectedItems};

    filter = memoize(
        (data, query, filteredItems) => data.filter((item) => {
            let value = item.toString();
            return value.startsWith(query) && !filteredItems.includes(value);
        })
    );

    componentDidMount() {
        this.props.statisticsActions.getDistinctValues(this.props.selector);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedItems !== this.props.selectedItems) {
            this.setState({selectedItems: this.props.selectedItems});
        }
    }

    onQueryChange = (event) => {
        event.preventDefault();

        let newState = {...this.state};
        newState.query = event.target.value;
        this.setState(newState);
    }

    onToggle = (isOpen, event, metadata) => {
        if (!isOpen) {
            document.getElementById('search').value = '';
            this.setState({query: ""});
        }
    }

    onSelectedItemClick = (event) => {
        event.preventDefault();
        
        let selectedItems = this.state.selectedItems.filter((element) => {
            return element !== event.target.text;
        })

        this.setState({selectedItems: selectedItems});

        this.props.onFilterChange(this.props.selector, selectedItems);
    }

    onMenuItemClick = (event) => {
        event.preventDefault();
        
        let selectedItems = [...this.state.selectedItems, event.target.text];
        this.setState({selectedItems: selectedItems});

        this.props.onFilterChange(this.props.selector, selectedItems);
    }

    getButtonColor = () => {
        return (this.state.selectedItems.length > 0 ? 'secondary' : 'primary');
    }

    render() {  
        let filteredData = this.filter(this.props.data, this.state.query, this.state.selectedItems);
        filteredData = filteredData.slice(0, this.props.limit);
        filteredData = filteredData.map(element => <Dropdown.Item className='item' onClick={this.onMenuItemClick} key={element}>{element}</Dropdown.Item>);

        /* add items to the dropdown menu in reverse order */
        if (this.state.selectedItems.length > 0) {
            filteredData.unshift(<Dropdown.Divider key='divider2'/>);
            let savedFilter = this.state.selectedItems.map(element => <Dropdown.Item className='item' onClick={this.onSelectedItemClick} key={element}>{element}</Dropdown.Item>);
            filteredData.unshift(savedFilter);
        }

        filteredData.unshift(<Dropdown.Divider key='divider'/>);
        filteredData.unshift(<input className='search' onChange={this.onQueryChange} type="text" id='search' key='search' placeholder={i18next.t('statistics.filter.search.placeholder')} aria-label="Search"></input>);

        return (
            <DropdownButton variant={this.getButtonColor()} onToggle={this.onToggle} id="dropdown-basic-button" title={this.props.title}>
                {filteredData}
            </DropdownButton>
        );
    }
}

export default withRouter(connect(
    (state, ownProps) => {
        return {
            data: state.statisticsState.distinctValues[ownProps.selector]
        };
    }, 
    (dispatch) => ({
        statisticsActions: bindActionCreators(StatisticsActions, dispatch)
    })
  )(QuerySelection));