import React from 'react';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'

import i18next from 'i18next';

import ParameterSelector from './ParameterSelector';
import QuerySelection from './QuerySelection';
import PieDisplay from '../Charts/Pie';
import BarDisplay from '../Charts/Bar';

import './Query.css';

export default class Query extends React.Component {
    static defaultProps = {
        filters: {},
        charts: [{key: i18next.t('statistics.charts.bar'), value: BarDisplay}, {key: i18next.t('statistics.charts.pie'), value: PieDisplay}],
        pivot: [{key: i18next.t('statistics.filter.location'), value: "location" }, 
                {key: i18next.t('statistics.filter.group'), value: "group"}, 
                {key: i18next.t('statistics.filter.division'), value: "division"}, 
                {key: i18next.t('statistics.filter.appraiser'), value: "appraiser"},
                {key: i18next.t('statistics.filter.decision_date'), value: "decision_date"},
                {key: i18next.t('statistics.filter.tax_before'), value: "tax_before"},
                {key: i18next.t('statistics.filter.tax_after'), value: "tax_after"},
                {key: i18next.t('statistics.filter.tax_remain_percentiles'), value: "tax_remain_percentiles"}]
    }

    getFilterParameters() {
        let keys = Object.keys(this.props.filters);
        let items = keys.map(key => <Col key={key} ><QuerySelection selectedItems={this.props.filters[key]} onFilterChange={this.props.onFilterChange} title={i18next.t('statistics.filter.' + key)} selector={key} /></Col>);

        return  (
            <Container>
                <Row>
                   {items}
                </Row>
            </Container>
        );
    }

    getQueryParameters() {
        return (
            <Container>
                <Row> 
                    <Col><ParameterSelector title={i18next.t('statistics.query.pivot')} options={this.props.pivot} onParameterSelection={this.props.onPivotSelection}/></Col>
                    <Col><ParameterSelector title={i18next.t('statistics.query.chart_type')} options={this.props.charts} onParameterSelection={this.props.onChartSelection}/></Col>
                </Row>
            </Container>
        );
    }

    render() {  
        return (
            <div>
                <Card className='card2'>
                    <Card.Header className='card-header2'>
                        {i18next.t('statistics.header.filter')}
                        <Button className='clear-filters' title={i18next.t('statistics.header.clear-filters')} onClick={this.props.clearAllFilters} />
                    </Card.Header>
                    <Card.Body className='card-body2'>
                        {this.getFilterParameters()}
                    </Card.Body>
                </Card>

                <Card className='card2'>
                    <Card.Header className='card-header2'>{i18next.t('statistics.header.query')}</Card.Header>
                    <Card.Body className='card-body2'>
                        {this.getQueryParameters()}
                    </Card.Body>
                </Card>
            </div>
        );
    }
}