import React from 'react';

import { Cell, Tooltip, PieChart, Pie, ResponsiveContainer } from 'recharts';

const randomColor = require('randomcolor');

export default class PieDisplay extends React.Component {
    static defaultProps = {
        data: [],
        colors: randomColor({count: 100}),
    }

    render() {  
        return (
            <ResponsiveContainer>
                <PieChart onMouseEnter={this.onPieEnter}>
                    <Pie
                        data={this.props.data}
                        cx="50%"
                        cy="50%"
                        innerRadius={0}
                        outerRadius='60%'
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="count"
                        nameKey="_id"
                        onClick={this.props.onChartItemClick}
                        label={(item) => { return item.name + ' : ' + item.value;}}
                        isAnimationActive={false}
                        >
                        {
                            this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={this.props.colors[index % this.props.colors.length]} />)
                        }
                    </Pie>
                    <Tooltip formatter={(value, name) => {return [name, value];}}/>
                </PieChart>
            </ResponsiveContainer>
        );
    }
}