import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile } from "react-device-detect";

import i18next from 'i18next';
import { Trans } from 'react-i18next';

import * as EventsActions  from '../../store/events/actions';

import InfoCard from './InfoCard';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import './Info.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        className='text2'
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }

class Info extends React.Component {
    state = {
        value: 0,
    }

    componentDidMount() {
        this.props.eventsActions.dispatch('info', 'impression');
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    }

    renderMobile() {
        return (
            <div className='main2'>
                <h4>{i18next.t('info.title')}</h4>
                <div className='content-mobile'>
                    <InfoCard title={i18next.t('info.what.title')} text={<React.Fragment><Trans i18nKey='info.what.text' /></React.Fragment>} />
                    <InfoCard title={i18next.t('info.when.title')} text={
                        <div>
                            <React.Fragment><Trans i18nKey='info.when.text1' /></React.Fragment>
                            <ul>
                                <li> {i18next.t('info.when.bullet1')} </li>
                                <li> {i18next.t('info.when.bullet2')} </li>
                                <li> {i18next.t('info.when.bullet2')} </li>
                            </ul>
                            <React.Fragment><Trans i18nKey='info.when.text2' /></React.Fragment>
                        </div>} />
                    <InfoCard title={i18next.t('info.sum.title')} text={<React.Fragment><Trans i18nKey='info.sum.text' /></React.Fragment>} />
                    <InfoCard title={i18next.t('info.exempt.title')} text={
                        <div>
                            <React.Fragment><Trans i18nKey='info.exempt.text' /></React.Fragment>
                            <ul>
                                <li> {i18next.t('info.exempt.bullet1')} </li>
                                <li> {i18next.t('info.exempt.bullet2')} </li>
                                <ul>
                                    <li> {i18next.t('info.exempt.plan.bullet1')} </li>
                                    <li> {i18next.t('info.exempt.plan.bullet2')} </li>
                                </ul>
                                
                                <li> {i18next.t('info.exempt.bullet3')} </li>
                                <li> {i18next.t('info.exempt.bullet4')} </li>
                            </ul>
                        </div>} />
                    <InfoCard title={i18next.t('info.appeal.title')} text={
                        <div>
                            <React.Fragment><Trans i18nKey='info.appeal.text' /></React.Fragment>
                            <a href='./statistics' > {i18next.t('ribbon.menu.statistics')}</a>
                        </div>} />
                </div>  
            </div>
        );
    }

    renderDesktop() {
        return (
            <div className='main2'>
                <h4>{i18next.t('info.title')}</h4>
                <div className='content-desktop'>
                    <Tabs
                    orientation='vertical'
                    variant="scrollable"
                    value={this.state.value}
                    onChange={this.handleChange}
                    className='tabs'
                    >
                    <Tab label={i18next.t('info.what.title')} id='0' aria-controls='vertical-tabpanel-0' className='tab-item'/>
                    <Tab label={i18next.t('info.when.title')} id='1' aria-controls='vertical-tabpanel-1' className='tab-item'/>
                    <Tab label={i18next.t('info.sum.title')} id='2' aria-controls='vertical-tabpanel-2' className='tab-item'/>
                    <Tab label={i18next.t('info.exempt.title')} id='3' aria-controls='vertical-tabpanel-3' className='tab-item'/>
                    <Tab label={i18next.t('info.appeal.title')} id='4' aria-controls='vertical-tabpanel-4' className='tab-item'/>
                    </Tabs>
                    <TabPanel value={this.state.value} index={0}>
                        <React.Fragment><Trans i18nKey='info.what.text' /></React.Fragment>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        <div>
                            <React.Fragment><Trans i18nKey='info.when.text1' /></React.Fragment>
                            <ul>
                                <li> {i18next.t('info.when.bullet1')} </li>
                                <li> {i18next.t('info.when.bullet2')} </li>
                                <li> {i18next.t('info.when.bullet3')} </li>
                            </ul>
                            <React.Fragment><Trans i18nKey='info.when.text2' /></React.Fragment>
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={2}>
                        <React.Fragment><Trans i18nKey='info.sum.text' /></React.Fragment>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={3}>
                        <div>
                            <React.Fragment><Trans i18nKey='info.exempt.text' /></React.Fragment>
                            <ul>
                                <li> {i18next.t('info.exempt.bullet1')} </li>
                                <li> {i18next.t('info.exempt.bullet2')} </li>
                                <ul>
                                    <li> {i18next.t('info.exempt.plan.bullet1')} </li>
                                    <li> {i18next.t('info.exempt.plan.bullet2')} </li>
                                </ul>
                                
                                <li> {i18next.t('info.exempt.bullet3')} </li>
                                <li> {i18next.t('info.exempt.bullet4')} </li>
                            </ul>
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={4}>
                        <div>
                            <React.Fragment><Trans i18nKey='info.appeal.text' /></React.Fragment>
                            <a href='./statistics' > {i18next.t('ribbon.menu.statistics')}</a>
                        </div>
                    </TabPanel>
                </div>
            </div>
        );
    }

    render() {
        if (isMobile) {
            return this.renderMobile();
        }

        return this.renderDesktop();
    }
}

export default connect(
    (state) => {
      return {
      };
    }, 
    (dispatch) => ({
        eventsActions: bindActionCreators(EventsActions, dispatch)
    })
  )(Info);