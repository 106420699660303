import React from "react";

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SignUpDialog from "./SignUpDialog";

export default class SignUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = { open: false };
    }

    openDialog = () => {
        this.setState({open: true});
    }

    closeDialog = () => {
        this.setState({open: false});
    }
    
    render() {
        return (
            <div>
                <Typography variant="subtitle1">Not a member?
                    <Button onClick={this.openDialog}>Sign Up</Button>
                </Typography>
                <SignUpDialog open={this.state.open} onDialogClose={this.closeDialog}/>
            </div>)
    }
}