import React from "react";
// import Login from "../Login";
import {connect} from "react-redux";
import { FacebookLoginButton } from "react-social-login-buttons";

/*global FB*/

class FacebookLogin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fb_id: "",
            first_name: "",
            last_name: "",
            email: "",
            photo_url: "",
            sdk_ready: false,
            isLoggedIn: false,
            loading: false,
            status: "",
            data: {},
            fields: "first_name, last_name, gender, email, picture"
        };

        this.doLogin = this.doLogin.bind(this);
        this.doLogout = this.doLogout.bind(this);
    }

    setProfile(data) {
        this.setState({first_name: data.first_name, last_name: data.last_name, photo_url: data.picture.url});
    }

    componentDidMount() {
        if (!this.state.sdk_ready) {
            this.setFacebookSDK().then(() => {
                this.setState({sdk_ready: true});
                return this.checkLoginState();
            }).then((res) => {

            }).catch((err) => {
                console.warn(err);
            })
        }
    }

    setFacebookSDK() {
        return new Promise((resolve, reject) => {
            if (this.state.sdk_ready) {
                resolve();
            } else {
                window.fbAsyncInit = function () {
                    FB.init({
                        appId: '493105064775182',
                        cookie: true,
                        xfbml: true,
                        version: 'v3.3'
                    });
                    FB.AppEvents.logPageView();
                };

                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {
                        return;
                    }
                    js = d.createElement(s);
                    js.id = id;
                    js.src = "https://connect.facebook.net/en_US/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));

                resolve();
            }
        });
    }

    checkLoginState() {
        return new Promise((resolve, reject) => {
            FB.getLoginStatus((res, err) => {
                if (!err) {
                    (res.status === 'connected') ? this.setState({isLoggedIn: true}) : this.setState({isLoggedIn: false});
                    resolve(res);
                } else {
                    reject(err);
                }
            });
        });
    }

    login() {
        return new Promise((resolve, reject) => {
            if (this.state.isLoggedIn) {
                resolve();
            } else {
                FB.login((response) => {
                    response.status === 'connected' ? resolve(response) : reject(response);
                });
            }
        })
    }

    logout() {
        return new Promise((resolve, reject) => {
            if (!this.state.isLoggedIn) {
                resolve();
            } else {
                FB.logout((response) => {
                    response.authResponse ? resolve(response) : reject(response);
                });
            }
        });
    }

    getInfo() {
        return new Promise((resolve, reject) => {
            FB.api(
                '/me',
                {fields: this.state.fields},
                response => response.error ? reject(response) : resolve(response)
            );
        });
    }

    doLogin() {
        this.setState({
            loading: true
        }, () => {
            this.setFacebookSDK().then(
                () => this.checkLoginState()
                )
                .then(
                (res) => {
                    this.setState({status: res.status});
                    if (res.status === 'connected') this.setState({isLoggedIn: true});
                    return this.login();
                    }
                )
                .then(
                () => this.getInfo()
                )
                .then(
                (res) => { this.setState({loading: false, isLoggedIn: true, data: res, status: 'connected'});
                console.log(res);}
                )
                .catch((error) => {
                    this.setState({loading: false, data: {}, status: 'unknown'});
                    console.warn(error);
                });
        });
    }

    doLogout() {
        this.setState({
            loading: true
        }, () => {
            this.setFacebookSDK().then(
                () => this.checkLoginState()
            )
            .then(
                (res) => {
                    (res.status === 'connected') ? this.setState({isLoggedIn: true}) : this.setState({isLoggedIn: false});
                    this.logout()
                }
            )
            .then(
                () => { this.setState({loading: false, isLoggedIn: false, data: {}, status: 'unknown'}); }
            )
            .catch(error => {
                this.setState({loading: false, data: {}, status: 'unknown'});
                console.warn(error);
            });
        });
    }

    render() {
        let click = this.doLogin;
        let text = <span>Log in with <b>Facebook</b></span>;

        if (this.state.isLoggedIn) {
            click = this.doLogout;
            text = <span> Logout </span>;
        }

        return this.state.sdk_ready && <FacebookLoginButton onClick={click}>
            {text}
        </FacebookLoginButton>
    }
}

export default connect(
    (state) => {
        return {
        };
    },
    (dispatch) => ({
    })
)(FacebookLogin);