import { handleActions } from 'redux-actions';

import { Types } from './actions';

export const initialState = {
  notifications: [],
}

export const notificationsReducer = handleActions(
  {
    [Types.NOTIFY_USER]: (state, action) => {
      const newState = { ...state };
      newState.notifications = [
        {header: action.notification.header, 
         body: action.notification.body, 
         show: true,
         timestamp: action.notification.timestamp}, 
         ...state.notifications,
        ];

      return newState;
    },
    [Types.DISMISS_NOTIFICATION]: (state, action) => {
      const newState = { ...state };
      newState.notifications = state.notifications.filter((item) => {
        return ((item.timestamp !== action.notification.timestamp) && (item.show));
      });

      return newState;
    },
  },
  initialState,
);

