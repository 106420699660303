import React from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';

import Ribbon from '../Ribbon/Ribbon';
import Home from '../Home/Home';
import Info from '../Info/Info';
import Statistics from '../Statistics/Statistics';
import Contact from '../Contact/Contact';
import Notification from '../Notification/Notification';

import './App.css';

class App extends React.Component {
  render() {
    return (
      <div>
        <I18nextProvider i18n={i18n}>
          <Ribbon />
          <div className='content'>
            <Switch >
              <Route exact path="/" component={Home} /> 
              <Route exact path="/info" component={Info} /> 
              <Route exact path="/statistics" component={Statistics} /> 
              <Route exact path="/contact" component={Contact} /> 
            </Switch>
          </div>
          <Notification />
        </I18nextProvider>
      </div>
    )
  }
}

export default withRouter(connect(
    (state) => {
      return {
      };
    }
  )(App));