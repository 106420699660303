import { handleActions } from 'redux-actions';

import { Types } from './actions';

export const initialState = {
    valuesForKey: {},
    distinctValues: {},
}

export const statisticsReducer = handleActions(
  {
    [Types.GET_VALUES_FOR_KEY_STARTED]: (state, action) => {
      const newState = { ...state };
      return newState;
    },
    [Types.GET_VALUES_FOR_KEY_COMPLETED]: (state, action) => {
      const newState = { ...state };

      newState.valuesForKey[action.key] = {maxValue: action.maxValue, values: action.values};
      return newState;
    },
    [Types.GET_DISTINCT_VALUES_STARTED]: (state, action) => {
      const newState = { ...state };
      return newState;
    },
    [Types.GET_DISTINCT_VALUES_COMPLETED]: (state, action) => {
      const newState = { ...state };

      newState.distinctValues[action.key] = action.values;
      return newState;
    },
    [Types.ERROR_OCCURED]: (state, action) => {
      const newState = { ...state };
      console.log(action.error);
      return newState;
    },
  },
  initialState,
);

