import { createStore, applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';

import { rootReducer } from './rootReducer';
import { rootState } from './state';
import { loadState } from './LocalStorage';

function configureStore(reducer, history, initialState) {
    let middleware = null;

    middleware = applyMiddleware(thunk, routerMiddleware(history));
    const st = createStore(reducer, initialState, middleware);

    return st;
}

const persistedState = loadState();

export const history = createBrowserHistory();
export const store = configureStore(rootReducer, history, persistedState ? persistedState : rootState);
