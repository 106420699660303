import { combineReducers } from 'redux';
import { userReducer } from './users/reducers';
import { statisticsReducer } from './statistics/reducers';
import { eventsReducer } from './events/reducers';
import { notificationsReducer } from './notifications/reducers';

export const rootReducer = combineReducers({
    userState: userReducer,
    statisticsState: statisticsReducer,
    eventsState: eventsReducer,
    notificationsState: notificationsReducer,
});