import { handleActions } from 'redux-actions';

import { Types } from './actions';

export const initialState = {
    isLoggedIn: false,
    token: "",
    username: "",
    emailStatus: 'idle',
}

function initializeState(state) {
  state.login.isLoggedIn = false;
  state.login.token = "";
  state.login.username = "";
}

export const userReducer = handleActions(
  {
    [Types.LOGIN_STARTED]: (state, action) => {
      const newState = { ...state };
      initializeState(newState);
      return newState;
    },
    [Types.LOGGED_IN]: (state, action) => {
      const newState = { ...state };
      newState.login.isLoggedIn = true;
      newState.login.token = action.token;
      return newState;
    },
    [Types.ERROR_OCCURED]: (state, action) => {
      const newState = { ...state };
      console.log(action.error);
      return newState;
    },
    [Types.SIGN_IN_STARTED]: (state, action) => {
      const newState = { ...state };
      initializeState(newState);
      return newState;  
    },
    [Types.USER_INFO]: (state, action) => {
      const newState = { ...state };
      newState.login.username = action.userInfo.username;
      return newState;  
    },
    [Types.LOGOUT]: (state, action) => {
      const newState = { ...state };
      initializeState(newState);
      return newState;  
    },
    [Types.SEND_EMAIL_STARTED]: (state, action) => {
      const newState = { ...state };
      newState.emailStatus = 'in progress';
      return newState; 
    },
    [Types.SEND_EMAIL_COMPLETED]: (state, action) => {
      const newState = { ...state };
      newState.emailStatus = 'completed';
      return newState;  
    }
  },
  initialState,
);

