import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {history} from '../../store';

import i18next from 'i18next';
import { Trans } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import * as UserActions  from '../../store/users/actions';
import * as EventsActions  from '../../store/events/actions';
import * as NotificationsActions  from '../../store/notifications/actions';

import './Contact.css';

class Contact extends React.Component {
    state = {
        email: "", 
        subject: "", 
        text: "",
    };

    componentDidMount() {
        this.props.eventsActions.dispatch('contact', 'impression');
    }

    handleChange = (event) => {
        event.preventDefault();

        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const header = i18next.t('notifications.header.prefix') + i18next.t('notifications.header.contact');
        const body = <Trans i18nKey='notifications.body.sending-email' />;
        this.props.notificationsActions.notify(header, body);

        this.props.userActions.contactByEmail(this.state.email, this.state.subject, this.state.text);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.emailStatus !== this.props.emailStatus) {
            switch(this.props.emailStatus) {
                case 'in progress':
                    return;
    
                case 'completed':
                    const header = i18next.t('notifications.header.prefix') + i18next.t('notifications.header.contact');
                    const body = <Trans i18nKey='notifications.body.email-sent' />;
                    this.props.notificationsActions.notify(header, body);
    
                    history.push('./');
                    return;
    
                default:
                    return;
            }
        }
    }

    render() {
        return (
            <div className='contact-div'>
                <h4>{i18next.t('contact.title')}</h4>
                <form className='contact-form' noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id='email'
                        label={i18next.t('contact.email')}
                        name={i18next.t('contact.email')}
                        autoComplete="email"
                        autoFocus
                        onChange={this.handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id='subject'
                        label={i18next.t('contact.subject')}
                        name={i18next.t('contact.subject')}
                        onChange={this.handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows="10"
                        rowsMax="10"
                        id='text'
                        label={i18next.t('contact.text')}
                        name={i18next.t('contact.text')}
                        onChange={this.handleChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{'margin': '20px auto', 'width': '200px', 'display': 'flex'}}
                        onClick={this.handleSubmit}
                    >
                        {i18next.t('contact.submit')} 
                    </Button>
                </form>  
            </div>    
        );
    }
}

export default connect(
    (state) => {
        return {
            emailStatus: state.userState.emailStatus,
        };
    },
    (dispatch) => ({
        userActions: bindActionCreators(UserActions, dispatch),
        eventsActions: bindActionCreators(EventsActions, dispatch),
        notificationsActions: bindActionCreators(NotificationsActions, dispatch),
    })
  )(Contact);