import React from 'react';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

import { getTaxMaxDiff } from '../statistics/actions';

export const Types = {
    NOTIFY_USER: 'NOTIFY_USER',
    DISMISS_NOTIFICATION: 'DISMISS_NOTIFICATION',
}

export const notify = (header, body) => {
    return function(dispatch) {
        dispatch({type: Types.NOTIFY_USER, notification: {header: header, body: body, timestamp: new Date().getTime()}});
    }
}

export const notifyFilterAndPivotAreEqual = () => {
    const header = i18next.t('notifications.header.prefix') + i18next.t('notifications.header.statistics');
    const body = <Trans i18nKey='notifications.body.filter-pivot' />;

    return notify(header, body);
}

export const notifyClearAllFilters = () => {
    const header = i18next.t('notifications.header.prefix') + i18next.t('notifications.header.statistics');
    const body = i18next.t('notifications.body.clear-filters');

    return notify(header, body);    
}

export const notifyTaxMaxDiff = (filters) => {
    return function(dispatch) {
        getTaxMaxDiff(filters, (maxDiff) => {
            if (maxDiff >= 0) {
                const header = i18next.t('notifications.header.prefix') + i18next.t('notifications.header.statistics');
                const body = i18next.t('notifications.body.max-diff', {amount: formatNumber(maxDiff)}); 
            
                dispatch({type: Types.NOTIFY_USER, notification: {header: header, body: body, timestamp: new Date().getTime()}});
            }
        });
    }
}

function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const dismiss = (timestamp) => {
    return function(dispatch) {
        dispatch({type: Types.DISMISS_NOTIFICATION, notification: {timestamp: timestamp}});
    }
}

