import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './InfoCard.css';


export default class InfoCard extends React.Component {
    state = {
        expanded: false,
    }

    handleExpandClick = () => {
        this.setState({expanded: !this.state.expanded});
    }

    render() {
        return (
            <Card className='info-card' >
            <CardHeader 
              titleTypographyProps={{variant: "h6"}}
              action={
                <IconButton
                  className={this.state.expanded ? 'info-card-icon-open' : 'info-card-icon'}
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
              }
              title={this.props.title}
            />
            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
              <CardContent>
                  {this.props.text}
              </CardContent>
            </Collapse>
          </Card>
        );
    }
}