import React from "react";

import TextField from '@material-ui/core/TextField';

export default class BasicCredentials extends React.Component {
    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    render() {
        return (
            <div>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email2"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={this.handleChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password2"
                    autoComplete="current-password"
                    onChange={this.handleChange}
                />
            </div>
        )
    }
}