import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as NotificationsActions  from '../../store/notifications/actions';

import { Toast } from 'react-bootstrap';

import './Notification.css';

class Notification extends React.Component {
    onClose = (timestamp) => {
        this.props.notificationsActions.dismiss(timestamp);
    }

    render() {  
        const notifications = this.props.notifications.map((notification) => {
            return (
                <Toast key={notification.timestamp} 
                    show={notification.show} animation={true} delay={5000} autohide
                    onClose={() => this.onClose(notification.timestamp)}>
                    <Toast.Header className='toast-header'>
                        <strong className="mr-auto">{notification.header}</strong>
                        <small>{new Date(notification.timestamp).toLocaleTimeString()}</small>
                    </Toast.Header>
                    <Toast.Body>{notification.body}</Toast.Body>
                </Toast>
            );
        });

        return (
            <div style={{position: 'fixed', bottom: 20, right: 20}} >
                {notifications}
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            notifications: state.notificationsState.notifications,
        };
    },
    (dispatch) => ({
        notificationsActions: bindActionCreators(NotificationsActions, dispatch),
    })
  )(Notification);