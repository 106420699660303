const axios = require('axios');
const SERVER = process.env.REACT_APP_SERVER;
const uidGenerator = require('uuid/v1');

export const Types = {
    EVENT_SENT_SUCCESSFULLY: 'EVENT_SENT_SUCCESSFULLY',
    GENERATE_USER_ID: 'GENERATE_USER_ID',
    ERROR_OCCURED: 'ERROR OCCURED',
}

export const dispatch = (page, name, data = {}) => {
    return function(dispatch, getState) {
        const url = SERVER + '/events/';
        let userIdentifier = getState().eventsState.userIdentifier;
        if (!userIdentifier) {
            userIdentifier = uidGenerator();
            dispatch({type: Types.GENERATE_USER_ID, userIdentifier: userIdentifier});
        }

        const payload = {
            page: page, 
            name: name, 
            environment: process.env.NODE_ENV, 
            userIdentifier: userIdentifier,
            data: JSON.stringify(data),
        };

        axios.post(url, {data: payload})
            .then(response => dispatch({type: Types.EVENT_SENT_SUCCESSFULLY}))
            .catch(err => dispatch({type: Types.ERROR_OCCURED, error: err}))
    }
}

