import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import BasicCredentials from "./BasicCredentials"

import * as UserActions from "../../store/users/actions";

class BasicLogin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            storeCredentials: false
        };
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleLoginSubmit = (event) => {
        event.preventDefault();
        this.props.userActions.login(this.state.email, this.state.password, this.state.storeCredentials);
    };

    render() {
        return (
            <form className='form' noValidate>
                <BasicCredentials/>
                <FormControlLabel
                    control={<Checkbox value="true" color="primary" id="storeCredentials"
                                       onChange={this.handleChange}
                        />}
                    label="Remember me"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className='submit'
                    onClick={this.handleLoginSubmit}
                >
                    Log In
                </Button>
            </form>
        )
    }
}

export default connect(
    (state) => {
        return {
        };
    },
    (dispatch) => ({
        userActions: bindActionCreators(UserActions, dispatch)
    })
)(BasicLogin);