import React from "react";

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import BasicCredentials from "./BasicCredentials";

export default class SignUpDialog extends React.Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.onDialogClose();
    }

    render() {
        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.props.open}>
                <DialogTitle id="signup_dialog">Sign Up</DialogTitle>
                <form id="signup_form" className='form' noValidate>
                    <BasicCredentials/>
                </form>
            </Dialog>
        )
    }
}