import React from 'react';

import i18next from 'i18next';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from 'recharts';

export default class BarDisplay extends React.Component {
    static defaultProps = {
        data: []
    }

    render() { 
        return (
            <ResponsiveContainer>
                <BarChart data={this.props.data} barCategoryGap='20%'>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="_id" interval={0} height={110} angle={-45} textAnchor="end" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar onClick={this.props.onChartItemClick} dataKey="count" name={i18next.t('statistics.charts.data-legend')} fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        );
    }
}