import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next';
import { Trans } from 'react-i18next'

import * as EventsActions  from '../../store/events/actions';

import './Home.css';

class Home extends React.Component {
    componentDidMount() {
        this.props.eventsActions.dispatch('home', 'impression');
    }

    render() {
        return (
            <div className='home-text'>
                <div className='home-info'> 
                    <h4>{i18next.t('home.title')}</h4>
                    <br/>
                    <React.Fragment><Trans i18nKey='home.exp' /></React.Fragment>
                    <a href='./contact' > {i18next.t('ribbon.contact')}</a>
                    <div className='home-image' />
                </div>
            </div>          
        );
    }
}

export default connect(
    (state) => {
      return {
      };
    }, 
    (dispatch) => ({
        eventsActions: bindActionCreators(EventsActions, dispatch)
    })
  )(Home);