import { handleActions } from 'redux-actions';

import { Types } from './actions';

export const initialState = {
  userIdentifier: null,
}

export const eventsReducer = handleActions(
  {
    [Types.EVENT_SENT_SUCCESSFULLY]: (state, action) => {
      const newState = { ...state };
      return newState;
    },
    [Types.GENERATE_USER_ID]: (state, action) => {
      const newState = { ...state };
      newState.userIdentifier = action.userIdentifier;
      return newState;
    },
    [Types.ERROR_OCCURED]: (state, action) => {
      const newState = { ...state };
      console.log(action.error);
      return newState;
    },
  },
  initialState,
);

