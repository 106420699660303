import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import memoize from "memoize-one";

import i18next from 'i18next';

import * as StatisticsActions  from '../../store/statistics/actions';

import PieDisplay from './Pie';
import BarDisplay from './Bar';

import './ChartFactory.css';

class Statistics extends React.Component {
    static defaultProps = {
        data: {},
        selector: "",
        limit: 10,
        chartType: i18next.t('statistics.charts.bar'),
        filters: {},
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.selector !== this.props.selector) || (prevProps.filters !== this.props.filters)) {
            this.getData();
        }
    }

    getData() {
        this.props.statisticsActions.getValuesForKey(this.props.selector, this.props.filters, this.props.limit);
    }
    
    onBarChartItemClick = (bar) => {
        this.props.onChartItemClick(bar._id);
    }

    onPieChartItemClick = (pie) => {
        this.props.onChartItemClick(pie.name);
    }

    formatData = memoize(
        (data, selector) => {
            if (!data.values) {
                return [];
            }

            let stepSize = 0;
            switch(selector) {
                case 'decision_date': 
                    return data.values.map((item) => { return {_id: item._id.month + "/" + item._id.year, count: item.count }});

                case 'tax_before':
                case 'tax_after':
                    stepSize = data.maxValue / this.props.limit;
                    return data.values.map((item) => {
                        return {_id: '[' + this.formatNumber(parseInt(item._id) * stepSize) + ' - ' + this.formatNumber((parseInt(item._id) + 1) * stepSize) + ']', count: item.count};
                    });

                case 'tax_remain_percentiles':
                    stepSize = data.maxValue / this.props.limit;
                    return data.values.map((item) => {
                        return {_id: '[' + this.formatNumber(parseInt(item._id) * stepSize) + '% - ' + this.formatNumber((parseInt(item._id) + 1) * stepSize) + '%]', count: item.count};
                    });

                default:
                    return data.values;
            }
        }
    );

    formatNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    getChart() {
        const data = this.formatData(this.props.data, this.props.selector);

        switch(this.props.chartType) {
            case i18next.t('statistics.charts.bar'):
                return (
                    <div className='bar-container'>
                        <BarDisplay onChartItemClick={this.onBarChartItemClick} data={data} selector={this.props.selector} />
                    </div>
                );

            case i18next.t('statistics.charts.pie'):
                return (
                    <div className='pie-container'>
                        <PieDisplay onChartItemClick={this.onPieChartItemClick} data={data} selector={this.props.selector} />
                    </div>
                )

            default:
                console.log('error');
                return "";
        }
    }

    render() {  
        return (
            <div>
                <h5>{i18next.t('statistics.filter.' + this.props.selector)}</h5>
                {this.getChart()}
            </div>
        );
    }
}

export default withRouter(connect(
    (state, ownProps) => {
        return {
            data: state.statisticsState.valuesForKey[ownProps.selector]
        };
    }, 
    (dispatch) => ({
        statisticsActions: bindActionCreators(StatisticsActions, dispatch)
    })
  )(Statistics));