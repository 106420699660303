import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next';

import Query from './Query';
import ChartFactory from '../Charts/ChartFactory';
import Explanation from './Explanation';

import * as EventsActions  from '../../store/events/actions';
import * as NotificationsActions  from '../../store/notifications/actions';

import './Statistics.css';

class Statistics extends React.Component {
    static ChangeType = {
        CLEAR_ALL_FILTERS: "CLEAR_ALL_FILTERS",
        PIVOT_CHANGE: "PIVOT_CHANGE",
        FILTER_CHANGE: "FILTER_CHANGE"
    }

    static defaultProps = {
        filters: {},
        keys: ['location', 'group', 'division', 'appraiser'],
    }

    constructor(props) {
        super(props);

        this.props.keys.forEach(key => {
            this.props.filters[key] = [];
        })

        this.state = {
            chartType: i18next.t('statistics.charts.bar'),
            selector: this.props.keys[0],
            filters: this.props.filters,
        };
    }

    componentDidMount() {
        this.props.eventsActions.dispatch('statistics', 'impression');
    }

    onChartItemClick = (value) => {
        /* check if this selector exists in the filter parameters */
        if (!this.props.keys.includes(this.state.selector)) {
            return;
        }

        let filterData = [...this.state.filters[this.state.selector], value];
        this.onFilterChange(this.state.selector, filterData);
    }

    onChartSelection = (event, option) => {
        event.preventDefault();
        
        this.setState({chartType: option.key});
    }

    onPivotSelection = (event, option) => {
        event.preventDefault();

        this.setState({selector: option.value}, () => {this.onChartCharacteristicsChange(Statistics.ChangeType.PIVOT_CHANGE)});   
    }

    onFilterChange = (filterName, filterData) => {
        let newState = {...this.state};
        newState.filters = {...newState.filters};
        newState.filters[filterName] = filterData;
        this.setState(newState, () => {this.onChartCharacteristicsChange(Statistics.ChangeType.FILTER_CHANGE)});
    }

    onChartCharacteristicsChange = (changeType) => {
        if (changeType === Statistics.ChangeType.CLEAR_ALL_FILTERS) {
            return this.props.notificationsActions.notifyClearAllFilters();
        }

        if (changeType === Statistics.ChangeType.PIVOT_CHANGE || changeType === Statistics.ChangeType.FILTER_CHANGE) {
            /* the chart would display only one item - notify the user */
            const filter = this.state.filters[this.state.selector];
            if (filter && filter.length === 1) {
                return this.props.notificationsActions.notifyFilterAndPivotAreEqual();
            }
        }

        if (changeType === Statistics.ChangeType.FILTER_CHANGE) {
            const filter = this.state.filters[this.props.keys[0]];
            if (filter && filter.length === 1) {
                return this.props.notificationsActions.notifyTaxMaxDiff(this.state.filters);
            }
        }
    }

    clearAllFilters = () => {
        this.setState({filters: this.props.filters}, () => {this.onChartCharacteristicsChange(Statistics.ChangeType.CLEAR_ALL_FILTERS)});    
    }

    render() {  
        return (
            <div >
                <div className='explanation'>
                    <Explanation />
                </div>
                <div className='main'>
                    <Query filters={this.state.filters} clearAllFilters={this.clearAllFilters} onChartSelection={this.onChartSelection} onPivotSelection={this.onPivotSelection} onFilterChange={this.onFilterChange} />
                    <ChartFactory onChartItemClick={this.onChartItemClick} selector={this.state.selector} chartType={this.state.chartType} filters={this.state.filters} />
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
      return {
      };
    }, 
    (dispatch) => ({
        eventsActions: bindActionCreators(EventsActions, dispatch),
        notificationsActions: bindActionCreators(NotificationsActions, dispatch),
    })
  )(Statistics);