import React from 'react';

import { Trans } from 'react-i18next'
import i18next from 'i18next';

export default class Explanation extends React.Component {
    render() {  
        return (
            <div>
                <h4>{i18next.t('statistics.explanation.title')}</h4>
                <ul>
                    <li>
                        {i18next.t('statistics.explanation.item1')} 
                        {i18next.t('statistics.explanation.item2')}
                    </li>
                    <li>{i18next.t('statistics.explanation.item3')}</li>
                    <li>
                        {i18next.t('statistics.explanation.item4')}
                        {i18next.t('statistics.explanation.item5')}
                    </li>
                </ul>
            </div>
        );
    }
}