import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {history} from '../../store';
import * as UserActions  from '../../store/users/actions';

import i18next from 'i18next';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import './Ribbon.css';

class Ribbon extends React.Component {
    static defaultProps = {
        userActions: null,
        isLoggedIn: false,
    }

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
        }
    }

    onLoginButtonClick = (event) => {
        if (this.props.isLoggedIn) {
            this.props.userActions.logout();
        } else {
            history.push('/login');
        }
    }  

    generateLoginButtonCaption = () => {
        return this.props.isLoggedIn ? "Logout": "Login";
    }

    setAnchorEl(value) {
        const newState = {...this.state};
        newState.anchorEl = value;
        this.setState(newState);
    }

    handleClick = (event) => {
        event.preventDefault();
        this.setAnchorEl(event.currentTarget);
    }
  
    handleClose = () => {
        this.setAnchorEl(null);
    }

    handleGetInfo = (event, link) => {
        event.preventDefault();
        
        history.push(link);
        this.handleClose();
    }

    onContactButtonClick = (event) => {
        history.push('/contact');
    }  

    render() {  
        return (
            <div>
                <AppBar position="fixed">
                    <Toolbar className='toolbar'>
                        <div className='buttons'>
                            <Button className='contact' color="inherit" onClick={this.onContactButtonClick} title={i18next.t('ribbon.contact')}>""</Button>
                        </div>
                        <div className='menu-div'>
                            <IconButton className='menu-button' edge="start" color="inherit" aria-label="Menu" onClick={this.handleClick}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                className='menu'
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                getContentAnchorEl={null}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={this.handleClose}
                            >
                                <MenuItem style={{'fontSize': '15px'}} onClick={(e) => this.handleGetInfo(e, './')}>{i18next.t('ribbon.menu.home')}</MenuItem>
                                <MenuItem style={{'fontSize': '15px'}} onClick={(e) => this.handleGetInfo(e, './info')}>{i18next.t('ribbon.menu.info')}</MenuItem>
                                <MenuItem style={{'fontSize': '15px'}} onClick={(e) => this.handleGetInfo(e, './statistics')}>{i18next.t('ribbon.menu.statistics')}</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default withRouter(connect(
    (state) => {
      return {
        isLoggedIn: state.userState.isLoggedIn,
      };
    }, 
    (dispatch) => ({
        userActions: bindActionCreators(UserActions, dispatch)
    })
  )(Ribbon));