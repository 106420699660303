import React from "react";

import './GoogleLogin.css';

/*global gapi*/

export default class GoogleLogin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            google_id: "",
            first_name: "",
            last_name: "",
            email: "",
            photo_url: "",
            sdk_ready: false,
            isLoggedIn: false,
            loading: false,
            status: "",
            data: {},
            fields: "profile email"
        };
    }

    componentDidMount() {
        this.renderButton();
    }

    onSignIn = (googleUser) => {
        var profile = googleUser.getBasicProfile();
        console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
    }

    onSuccess = (googleUser) => {
        console.log('Logged in as: ' + JSON.stringify(googleUser.getBasicProfile()));
    }
    onFailure = (error) => {
        console.log(error);
    }

    renderButton() {
        gapi.signin2.render('GoogleSignInButton', {
            'scope': this.state.fields,
            'height': 40, 
            'width': 160,
            'margin': 5,
            'padding': 10,
            'longtitle': true,
            'theme': 'light',
            'onsuccess': this.onSignIn,
            'onfailure': this.onFailure
        });
    }

    render() {
        return <div className='button' id="GoogleSignInButton"/>
    }
}