import React from 'react';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import './QuerySelection.css';

export default class ParamerSelector extends React.Component {
    static defaultProps = {
        title: "",
        options: [],
    }

    render() {  
        const items = this.props.options.map((option) => {
            return <Dropdown.Item className='item' key={option.key} onClick={(event) => this.props.onParameterSelection(event, option)}>{option.key}</Dropdown.Item>
        });

        return (
            <DropdownButton id="dropdown-basic-button" title={this.props.title}>
                {items}
            </DropdownButton>
        );
    }
}