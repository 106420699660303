const axios = require('axios');
const SERVER = process.env.REACT_APP_SERVER;

export const Types = {
    GET_QUERY_FIELDS_STARTED: 'GET_QUERY_FIELDS_STARTED',
    GET_QUERY_FIELDS_COMPLETED: 'FOLLOW COMPLETED',
    GET_VALUES_FOR_KEY_STARTED: 'GET_VALUES_FOR_KEY_STARTED',
    GET_VALUES_FOR_KEY_COMPLETED: 'GET_VALUES_FOR_KEY_COMPLETED',
    GET_DISTINCT_VALUES_STARTED: 'GET_DISTINCT_VALUES_STARTED',
    GET_DISTINCT_VALUES_COMPLETED: 'GET_DISTINCT_VALUES_COMPLETED',
    ERROR_OCCURED: 'ERROR OCCURED',
}

export const getValuesForKey = (key, filters, limit=20, offset=0) => {
    return function(dispatch) {
        dispatch({type: Types.GET_VALUES_FOR_KEY_STARTED});

        let url = SERVER + '/stats/values/' + key;
        axios.get(url, {params: { filters: JSON.stringify(filters), 'limit': limit, 'offset': offset }})
            .then(response => {
                dispatch({type: Types.GET_VALUES_FOR_KEY_COMPLETED, 
                    key: response.data.key, 
                    limit: limit,
                    offset: offset,
                    maxValue: response.data.maxValue,
                    values: response.data.values});
            })
            .catch(err => dispatch({type: Types.ERROR_OCCURED, error: err}));
    }
}

export const getDistinctValues = (key) => {
    return function(dispatch) {
        dispatch({type: Types.GET_DISTINCT_VALUES_STARTED});

        let url = SERVER + '/stats/distinct/' + key;
        axios.get(url)
            .then(response => {
                dispatch({type: Types.GET_DISTINCT_VALUES_COMPLETED, 
                    key: response.data.key, 
                    values: response.data.values});
            })
            .catch(err => dispatch({type: Types.ERROR_OCCURED, error: err}));
    }
}

export const getTaxMaxDiff = (filters, callback) => {
    let url = SERVER + '/stats/max-diff';
    axios.get(url, {params: { filters: JSON.stringify(filters) }})
        .then(response => {
            return callback(response.data.value);
        })
        .catch(err => {return callback(-1)});    
}


