import i18n from 'i18next';
import he from '../i18n/locales/he.json';

const options = {
  debug: false,

  lng: 'he',

  resources: {
    he: {
      common: he,
    }
  },

  fallbackLng: 'he',

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },
};

i18n.init(options);

export default i18n;